/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD PERSON SMALL
|--------------------------------------------------------------------------
*/

.card-political-party {
    position: relative;

    min-width: 18.75rem;
    min-height: 5rem;
    margin: space.$space-8 space.$space-4;
    padding: space.$space-24 space.$space-16 space.$space-16 space.$space-16;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-100;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__avatar {
        width: 3rem;
        height: 3rem;
        border-radius: border.$radii-full;
        background-color: color.$color-gray-200;

        img,
        svg {
            width: 100%;
            height: 100%;
            margin: 0 auto;
            border-radius: border.$radii-full;
        }
    }

    &__content {
        margin-left: space.$space-16;

        .badge {
            margin-left: 0;
        }

        &__name {
            line-height: font.$lh-short;
            font-size: font.$fs-xs;
            color: color.$color-gray-400;

            &:first-child {
                font-weight: font.$fw-extra-bold;
                color: color.$color-black-50;
            }
        }
    }

    &__trigger {
        position: absolute;
        top: 0;
        right: 10px;
        transform: translateY(-50%);

        width: fit-content;
        height: auto;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    &:hover {
        transition: all .5s ease-in-out;
        box-shadow: shadow.$shadow-light-1;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .card-political-party {
        width: 100%;
    }
}


/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.card-political-party {
        width: 100%;
		border-radius: 0;
		border-bottom: 0.063rem solid #D5D3D0;

		&__content {
			display: flex;
			align-items: center;
			justify-content: center;
		}
    }
}
