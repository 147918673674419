/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD SPEAK CONTROL...
|--------------------------------------------------------------------------
*/

.speak-control {
    position: relative;

    width: 100%;
    margin: space.$space-16;
    padding: space.$space-32;
    border-radius: border.$radii-lg;
    border: border.$bw-thin solid color.$color-gray-50;
    background-color: color.$color-white;

    &__wrapper {
        width: 100%;
        height: 100%;
    }

    &__inner {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__header {
        width: 7rem;
        height: auto;
        border-radius: border.$radii-md;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__body {
        padding: 0 space.$space-24;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        h6 {
            font-size: font.$fs-md;
        }

        p {
            font-size: font.$fs-sm;
        }
    }

    &__footer {
        width: 100%;
        margin-top: space.$space-32;

        display: none;

        .wrapper-group {
            margin: 0;
        }
    }

    &__avatar {
        position: relative;

        width: 5rem;
        height: 5rem;
		border: border.$bw-2thick solid color.$color-gray-200;
        border-radius: border.$radii-full;
        background-color: color.$color-white;
        // outline-offset: 0.25rem;
        // outline: border.$bw-thick solid color.$color-400;

        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
            width: 100%;
            height: 100%;
            border-radius: border.$radii-full;
            object-fit: cover;
            object-position: top;
        }

        &__letter {
            position: absolute;
            top: 5%;
            right: -7%;

            width: 1.5rem;
            height: 1.5rem;
            border-radius: border.$radii-full;
            background-color: color.$color-300;

            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
                font-size: font.$fs-2xs;
                text-transform: uppercase;
                margin: 0;
            }
        }
    }

    &:hover {
        cursor: pointer;
        transition: 0.5s all linear;
        border-color: transparent;
        box-shadow: 0 0.5rem 1.5rem rgba(color.$color-gray-100, opacity.$op-semi-opaque);
    }

	&--small {
		min-height: 6rem;
		padding: 0.3rem;
		margin: 0.3rem 0;

		.speak-control__wrapper,
		.speak-control__inner {
			width: 100%;
			height: auto;
		}
	}

    &--active {
        cursor: pointer;
        transition: 0.5s all linear;
        border-color: transparent;
        box-shadow: 0 0.5rem 1.5rem rgba(color.$color-gray-100, opacity.$op-semi-opaque);

        .speak-control__footer {
            display: block;
        }
    }

	&--color {
		
	}
}
