/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| WRAPPER GROUP - Um embrulho genérico...
|--------------------------------------------------------------------------
*/

.wrapper-group {
    width: 100%;
    margin: space.$space-16 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &--fit {
        width: fit-content;
    }

    &--small {
        margin: 0;
    }

    &--start {
        justify-content: flex-start;
    }

    &--evenly {
        justify-content: space-evenly;
    }

    &--end {
        justify-content: flex-end;
    }

    &--space {
        justify-content: space-between;
    }

    &--col {
        flex-direction: column;
    }

	&--startA {
        align-items: flex-start;
    }

    &--endA {
        align-items: flex-end;
    }
    &--flow {
        flex-flow: row wrap;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .wrapper-group {

        &--start,
        &--end,
        &--space {
            justify-content: center;
        }
    }
}


/*
|--------------------------------------------------------------------------
| CARD WRAPPER
|--------------------------------------------------------------------------
*/

.card-wrapper {
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;

    &--column {
        flex-direction: column;
    }
}
