/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| IMAGE PREVIEW
|--------------------------------------------------------------------------
*/

.preview-image {
    padding: space.$space-8;

    &__inner {
        width: 5rem;
        height: 5rem;
        margin: space.$space-8;
        border-radius: border.$radii-full;
        background-color: color.$color-white;
        box-shadow: shadow.$shadow-dark-8;

        img,
        svg {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            border-radius: border.$radii-full;
        }
    }
}


/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .preview-image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
