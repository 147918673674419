/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD PERSON
|--------------------------------------------------------------------------
*/

.card-person2 {
    width: 20rem;
    min-height: 14.5rem;
    margin: space.$space-4;
    border-radius: border.$radii-lg;
    border: border.$bw-thin solid transparent;
    background-color: color.$color-white;
    box-shadow: 0 0.5rem 1.5rem rgba(color.$color-gray-100, opacity.$op-semi-opaque);

    &__wrapper {
		position: relative;

		width: 100%;
        height: 100%;
		padding: space.$space-16 space.$space-24;
	}

    &__body {
		margin-top: space.$space-16;

        h6 {
			font-size: font.$fs-3xl;
		}
    }

    &__avatar {
        position: relative;

        width: 3.5rem;
        height: 3.5rem;
		border: border.$bw-2thick solid color.$color-gray-200;
        border-radius: border.$radii-full;

		display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
            width: 100%;
            height: 100%;
            border-radius: border.$radii-full;
            object-fit: cover;
            object-position: top;
        }

        &__letter {
            position: absolute;
            top: 5%;
            right: -25%;

            width: 1.5rem;
            height: 1.5rem;
            border-radius: border.$radii-full;
            background-color: color.$color-300;

            display: flex;
            align-items: center;
            justify-content: center;

            h6 {
                font-size: font.$fs-2xs;
                text-transform: uppercase;
                margin: 0;
                
            }
        }
    }

	&__avatar-political-party {
		position: absolute;
		top: 10px;
		right: 10px;

		width: 3.75rem;
		height: auto;
		border-radius: border.$radii-full;

		&.badge {
			width: fit-content;
			border-radius: border.$radii-md;
		}
	}

	&.person-voted--in-favor {
		background-color: color.$color-green-50;
	}

	&.person-voted--against {
		background-color: color.$color-red-50;
	}

	&.person-voted--abstain {
		background-color: color.$color-blue-50;
	}

	&.person-voted--in-favor,
	&.person-voted--against,
	&.person-voted--abstain {
		.card-person2__body {
			h6 {
				color: color.$color-white;
			}
		}
	}

    &:hover {
        transition: 0.5s all linear;
        border-color: color.$color-gray-50;
        box-shadow: none;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (min-width: (1485px)) and (max-width: break.$break-4xxl) {
	.card-person2 {
		width: 21rem;
        min-height: 7.5rem;

        &__body {
            margin-top: space.$space-8;
    
            h6 {
                font-size: font.$fs-xl;
                overflow: hidden; // Removendo barra de rolagem
                text-overflow: ellipsis; // Adicionando "..." ao final
                display: -webkit-box;
                -webkit-line-clamp: 1; // Quantidade de linhas
                -webkit-box-orient: vertical;
            }
        }
	}
}

// @media (max-width: break.$break-4xxl) {
// 	.card-person2 {
// 		width: 15rem;

// 		&__body {
// 			h6 {
// 				font-size: font.$fs-xl;
// 			}
// 		}
// 	}
// }

@media (min-width: (break.$break-3sm + 1)) and (max-width: break.$break-3md) {
	.card-person2 {
		width: 47%;
    }
}

@media (max-width: break.$break-3sm) {
    .card-person2 {
		width: 100%;
        margin: space.$space-8 0;
    }
}
