/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| INPUT RADIO
|--------------------------------------------------------------------------
*/

input:is([type="radio"]) {
    cursor: pointer;
}

.radio {
	width: fit-content;

	display: flex;
	align-items: center;
	justify-content: center;

    &__input {
        cursor: pointer;
        width: 1.5rem;
        height: 1.5rem;
    }

    &__label {
        cursor: pointer;
        padding-left: 0.5rem;
    }
}
