/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| BUTTON
|--------------------------------------------------------------------------
*/

.button {
    position: relative;
    cursor: pointer;

    width: fit-content;
    height: auto;
    margin: space.$space-4;
    padding: 0 0 0 space.$space-16;
    border: border.$bw-thin solid transparent;
    border-radius: border.$radii-md;
    background-color: color.$color-gray-200;
    transition: background 300ms ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    text-transform: uppercase;
    font-size: font.$fs-xs;
    font-weight: font.$fw-extra-bold;
    line-height: font.$lh-short;
    letter-spacing: 0.063rem;
    color: color.$color-black-50;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0%;
        height: 100%;
        border-radius: border.$radii-md;
        background-color: transparent;
        transition: width 0.85s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &__text {
        z-index: 1;
    }

    .icon-wrapper {
        margin-left: space.$space-16;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: inherit;
        filter: brightness(88%);

        .icon__ {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
            background-color: color.$color-black-50;
        }
    }

    // BUTTON DESABILITADO...
    &:is([disabled]) {
        cursor: not-allowed;
        opacity: opacity.$op-light;
    }

    // BOTÃO COM FOCUS OU HOVER, QUE NÃO ESTEJA DESABILITADO...
    &:is(:focus, :hover):not([disabled]) {
        &::before {
            width: 100%;
            background-color: color.$color-gray-300;
        }
    }

    // BUTTON ROXO PRIMÁRIO...
    &--primary {
        background-color: color.$color-700;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-800;
            }
        }
    }

    // BUTTON ROXO SEDUNDÁRIO...
    &--secondary {
        background-color: color.$color-600;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-700;
            }
        }
    }

    // // BUTTON ROXO PRIMÁRIO...
    // &--primary {
    //     background-color: color.$color-700;

    //     &:is(:focus, :hover):not([disabled]) {
    //         &::before {
    //             background-color: color.$color-800;
    //         }
    //     }
    // }

    // // BUTTON ROXO SEDUNDÁRIO...
    // &--secondary {
    //     background-color: color.$color-500;

    //     &:is(:focus, :hover):not([disabled]) {
    //         &::before {
    //             background-color: color.$color-600;
    //         }
    //     }
    // }

    // BUTTON ROXO TERCIÁRIO...
    &--tertiary {
        background-color: rgba(color.$color-700, opacity.$op-light);

        .button__text {
            color: color.$color-700;
        }

        .icon-wrapper .icon__ {
            background-color: color.$color-700;
        }

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: rgba(color.$color-800, opacity.$op-medium);
            }
        }
    }

    &--primary,
    &--secondary,
    &--blue {
        color: color.$color-white;

        .icon-wrapper {
            .icon__ {
                background-color: color.$color-white;
            }
        }
    }

    // BUTTON LARANJA...
    &--alternative {
        background-color: color.$color-300;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-400;
            }
        }
    }

    // BUTTON VERDE...
    &--success {
        background-color: color.$color-green-50;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-green-100;
            }
        }
    }

    // BUTTON AMARELO...
    &--warning {
        background-color: color.$color-yellow-50;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-yellow-100;
            }
        }
    }

    // BUTTON VERMELHO...
    &--danger {
        background-color: color.$color-red-50;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-red-100;
            }
        }
    }

    // BUTTON BLUE...
    &--blue {
        background-color: color.$color-blue-50;

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-blue-100;
            }
        }
    }

    &--ghost {
        background-color: transparent;

        .icon-wrapper {
            .icon__ {
                background-color: color.$color-gray-400;
            }
        }

        &:is(:focus, :hover):not([disabled]) {
            &::before {
                background-color: color.$color-gray-50;
            }
        }
    }

    // BUTTON OUTLINED...
    &--outlined {
        background-color: transparent;
        border: border.$bw-2thick solid color.$color-gray-100;
        color: color.$color-700;

        .icon-wrapper {
            // background-color: color.$color-gray-200;
            // filter: brightness(100%);

            .icon__ {
                background-color: color.$color-gray-400;
            }
        }

        &:is(:focus, :hover):not([disabled]) {
            // color: color.$color-white;

            &::before {
                background-color: color.$color-600;
            }

            .icon-wrapper {
                background-color: transparent;
                // filter: brightness(88%);

                .icon__ {
                    background-color: color.$color-black-50;
                }
            }

        }
    }

    // BUTTON GRANDE...
    &--large {
        border-radius: border.$radii-lg;

        &:before {
            border-radius: border.$radii-lg;
        }

        .icon-wrapper {
            width: 4rem;
            height: 4rem;
            border-top-right-radius: border.$radii-lg;
            border-bottom-right-radius: border.$radii-lg;

            .icon__ {
                width: 2.3rem;
                height: 2.3rem;
            }
        }
    }

    // BUTTON PEQUENO...
    &--small {
        // width: 2.5rem;
        // height: 2.5rem;
        border-radius: border.$radii-sm;

        &:before {
            border-radius: border.$radii-sm;
        }

        .icon-wrapper {
            width: 2.2rem;
            height: 2.2rem;
            border-top-right-radius: border.$radii-sm;
            border-bottom-right-radius: border.$radii-sm;
        }
    }

    &--small--2x {
        // width: 2.5rem;
        // height: 2.5rem;
        border-radius: border.$radii-sm;
        font-size: font.$fs-xs;

        &:before {
            border-radius: border.$radii-sm;
        }

        .icon-wrapper {
            width: 1.8rem;
            height: 1.8rem;
            border-top-right-radius: border.$radii-sm;
            border-bottom-right-radius: border.$radii-sm;
        }
    }

    // BUTTON APENAS ICONE...
    &--icon {
        padding: 0;

        .icon-wrapper {
            margin-left: 0;
            border-top-left-radius: border.$radii-md;
            border-bottom-left-radius: border.$radii-md;
            background-color: transparent;
        }
    }

	&--table {
		margin: 0;
	}

	&--full {
		width: 100%;
	}
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media(max-width: break.$break-2sm) {
    .button {
        &--full-mobile {
            width: 100%;
            justify-content: space-between;

            .button__text {
                width: 100%;
                padding-left: 50%;
                transform: translateX(-20%);
            }
        }
    }
}
