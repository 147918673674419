/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD ORDER
|--------------------------------------------------------------------------
*/

// .card-order {
//     position: relative;

//     width: 20rem;
//     height: auto;
//     margin: space.$space-32 space.$space-8;
//     padding: space.$space-8;
//     border-radius: border.$radii-lg;
//     background-color: color.$color-background-50;
//     box-shadow: shadow.$shadow-light-1;

//     &__head {
//         position: absolute;
//         top: 0;
//         left: 50%;
//         transform: translate(-50%, -50%);

//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;

//         width: 12rem;
//         height: 6rem;
//         padding: space.$space-8;
//         border-radius: border.$radii-md;
//         background-color: color.$color-700;

//         p {
//             color: color.$color-white;
//         }

//         &::before,
//         &::after {
//             content: '';
//             position: absolute;
//             top: 50%;
//             transform: translateY(-50%);

//             width: 1rem;
//             height: 1.5rem;
//             background-color: color.$color-white;
//         }

//         &::before {
//             left: 0;

//             border-top-right-radius: border.$radii-full;
//             border-bottom-right-radius: border.$radii-full;
//         }

//         &::after {
//             right: 0;

//             border-top-left-radius: border.$radii-full;
//             border-bottom-left-radius: border.$radii-full;
//         }
//     }

//     &__body {
//         width: 100%;
//         height: 100%;
//         padding: space.$space-72 0 space.$space-16 0;

//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-wrap: wrap;
//     }

//     &__footer {
//         width: 90%;
//         min-height: 3rem;
//         height: auto;
//         margin: 0 auto;
//         border-top: border.$bw-thin solid color.$color-gray-100;
//     }

//     &--large {
//         width: fit-content;
//     }

//     &--print {
//         width: 100%;
//         padding: space.$space-32;
//         border: border.$bw-thin solid color.$color-gray-100;
//         box-shadow: none;

//         .card-order__head {
//             position: relative;
//             inset: auto;
//             transform: translate(0, 0);

//             width: 100%;
//             height: auto;
//             padding: 0;
//             border-radius: border.$radii-md;
//             background-color: transparent;

//             flex-direction: row;
//             justify-content: flex-start;

//             &::before,
//             &::after {
//                 display: none;
//             }

//             p {
//                 font-family: font.$ff-highlight;
//                 font-size: font.$fs-xl;
//                 color: color.$color-black-50;
//             }

//             .icon__ {
//                 margin-right: space.$space-8;
//                 background-color: color.$color-700;
//             }
//         }

//         .card-order__body {
//             padding-top: space.$space-8;
//             justify-content: flex-start;
//         }
//     }
// }

// @media (max-width: break.$break-2sm) {
//     .card-order {
//         width: 100%;
//         margin: space.$space-32 0;
//     }
// }


.card-order {
    position: relative;

    width: 25rem;
    height: auto;
    margin: space.$space-32 space.$space-8;
    padding: space.$space-32;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;
    box-shadow: shadow.$shadow-light-1;

    &__head {
        position: relative;

        width: 100%;
        height: auto;
        padding: space.$space-8;

        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 8px;
        //     left: 0;

        //     width: 1rem;
        //     height: 2rem;
        //     border-radius: border.$radii-sm;
        //     background-color: color.$color-detail-3;
        // }

        // h6 {
        //     margin: 0;
        //     padding-left: space.$space-16;
        // }
    }

    &__body {
        width: 100%;
        // height: 100%;
        padding: space.$space-40 0 space.$space-16 0;

        display: flex;
        align-items: flex-start;
        justify-content: center;

        .icon-wrapper {
            width: 3rem;
        }

        p {
            flex: 1;
            padding-left: space.$space-16;
        }
    }

    &__footer {
        width: 90%;
        min-height: 3rem;
        height: auto;
        margin: 0 auto;
    }

    &--large {
        width: fit-content;
    }

    &--print {
        width: 100%;
        padding: space.$space-32;
        border: border.$bw-thin solid color.$color-gray-100;
        box-shadow: none;

        .card-order__body {
            padding-top: space.$space-8;
            justify-content: flex-start;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .card-order {
        width: 100%;
        margin: space.$space-32 0;
        padding: space.$space-32 space.$space-16;

        &__body {
            flex-direction: column;
            // align-items: center;

            p {
                margin-top: space.$space-16;
                padding: 0;
            }
        }
    }
}


/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.card-order {
		width: 100%;
		margin: 0 0 space.$space-32 0;
		padding: 0;
		border-radius: 0;
		box-shadow: none;
		border-bottom: 0.063rem solid #f4f4f4;

		&__body {
			padding: 0;

			.icon-wrapper {
				display: none;
			}
		}

		&__footer {
			display: none;
		}
	}
}
