/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| IFRAME
|--------------------------------------------------------------------------
*/

iframe {
    width: 100%;
    height: 100%;
    border-radius: border.$radii-md;
    border: border.$bw-thin solid color.$color-gray-50;
}
