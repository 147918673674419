/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| SCROLL BAR SIDEBAR
|--------------------------------------------------------------------------
*/

.sidebar .sidebar__wrapper .sidebar__body {
    // Firefox...
    scrollbar-width: auto; // values: auto, thin ou none.
    scrollbar-color: transparent;

    // BAR (Chrome, Edge e Safari)...
    &::-webkit-scrollbar {
        width: 0.75rem;
    }

    // BAR FUNDO...
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    // BAR ALÇA...
    &::-webkit-scrollbar-thumb {
        border-radius: border.$radii-sm;
        border: border.$bw-heavy solid transparent;
        background-color: transparent;
    }
}
