/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| LAYOUT SITE
|--------------------------------------------------------------------------
*/

.layout-website {
    position: relative;

    width: 100%;
    height: 100vh;
    overflow: hidden;

    #brand,
    #loginLandingPage {
        z-index: 5;
        position: fixed;
        top: 30px;
    }

    #brand {
        left: 100px;

        img,
        svg {
            width: 13rem;
        }
    }

    #loginLandingPage {
        right: 100px;
    }

    &__wrapper,
    &__inner {
        width: 100%;
        height: 100%;
    }

    &__content {
        width: 100%;
        height: 100%;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media(max-width: break.$break-2lg) {
    .layout-website {
        overflow-y: auto;
    }
}

@media (max-width: 500px) {
    .layout-website {
        #loginLandingPage {
            display: none;
        }

        #brand {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
