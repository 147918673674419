/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| ERROR FOR INPUTS
|--------------------------------------------------------------------------
*/

.invalid-feedback {
    display: block;

    width: 100%;
	margin-top: space.$space-8;
    padding: space.$space-8;
    border-radius: border.$radii-md;
    background-color: rgba(color.$color-red-50, 0.1);

    font-size: font.$fs-sm;
    font-weight: font.$fw-extra-bold;
    color: color.$color-red-50;
}
