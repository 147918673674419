/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| TIMER...
|--------------------------------------------------------------------------
*/

.timer {
    position: relative;
    width: 100%;
    height: auto;
    margin: space.$space-32 space.$space-16;
    padding: space.$space-64 space.$space-16 space.$space-16 space.$space-16;
    border: border.$bw-thin solid color.$color-gray-50;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__name {
        position: absolute;
        top: -12px;
        left: 20px;

        padding: 0 space.$space-8;
        border-radius: border.$radii-md;
        background-color: color.$color-background-100;

        font-size: font.$fs-xs;
        font-weight: font.$fw-bold;
        // color: color.$color-text-50;
        text-transform: uppercase;
    }

    &--simple,
    &--simple-variation {
        margin: 0 auto;
        padding: space.$space-16;
        border: none;
        background-color: transparent;

        .timer__name {
            display: none;
        }

        .timer__temp {
            margin: 0;
            color: color.$color-white;
            -webkit-text-stroke: 0.128rem color.$color-black-50;
        }
    }

    &--simple {
        padding: space.$space-16 space.$space-32;
        background-color: color.$color-800;

        .timer__temp {
            font-size: font.$fs-10xl;
        }
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-3md) {
    .timer {
        &--simple {
            padding: space.$space-16;
        }
    }
}


@media (max-width: break.$break-2sm) {
    .timer {
        &--simple {
            .timer__temp {
                font-size: font.$fs-4xl;
            }
        }
    }
}
