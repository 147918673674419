/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD BASIC TRIGGER...
|--------------------------------------------------------------------------
*/

.card-basic-trigger {
    position: relative;

    width: 30rem;
    min-height: 16.25rem;
    height: auto;
    margin: space.$space-8 space.$space-4;
    padding: space.$space-24 space.$space-24 space.$space-8 space.$space-24;
    border-radius: border.$radii-lg;
    background-color: color.$color-background-50;
    box-shadow: shadow.$shadow-light-1;

    &__body {
        padding-bottom: space.$space-24;

        p {
            padding-left: space.$space-16;
        }
    }

    &__footer {
        width: 100%;
        height: auto;
        padding-top: space.$space-8;
        border-top: border.$bw-thick solid #f4f4f4;
    }

	&--large {
        min-height: 16.25rem;
    }
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .card-basic-trigger {
        width: 100%;

        &__body {
            p {
                padding: 0;
            }
        }
    }
}


/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.card-basic-trigger {
        width: 100%;
		min-height: auto;

		margin: space.$space-4;
		padding: 0;
		border-radius: 0;
		border-top: border.$bw-thin solid #f4f4f4;
		box-shadow: none;

		&--large {
			min-height: auto;
		}

		&__body {
			padding: 0;
		}

		&__footer {
			display: none;
		}
	}
}
