/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;

/*
|--------------------------------------------------------------------------
| SELECT
|--------------------------------------------------------------------------
*/

.select {
    cursor: pointer;

    width: 100%;
    height: 3.5rem;
    padding: space.$space-8 space.$space-16;
    border: border.$bw-2thick solid color.$color-gray-100;
    border-radius: border.$radii-md;
    background-color: transparent;
    transition: all .8s ease;

    option {
        padding: space.$space-16;
        color: color.$color-gray-400;
    }

    +.label:not(.label__checkbox) {
        top: -15px;
    }

    // Input com foco ou active
    &:is(:focus, :active) {
        border-color: color.$color-400;

        +.label:not(.label__checkbox) {
            color: color.$color-500;
        }
    }

    // Quando não tem foco ou active, remove o placeholder...
    &:not(:focus, :active)::placeholder {
        opacity: 0;
    }
}
