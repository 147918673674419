/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/spacings' as space;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| FORM ITEM
|--------------------------------------------------------------------------
*/

.form-item {
    position: relative;

    min-width: 18.75rem;
    margin: 0 space.$space-4 space.$space-32 space.$space-4;

	&--no-space {
		margin: 0;
	}

    &.form-item--checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &--preview {
        min-width: 5rem;
    }

	&#file-input {
		position: relative;

		.label--file {
			z-index: 1;
			position: absolute;
			top: 0;
		}
	}
}

/*
|--------------------------------------------------------------------------
| MEDIA QUERIES...
|--------------------------------------------------------------------------
*/

@media (max-width: break.$break-2sm) {
    .form-item {
        min-width: auto;
        width: 100%;

		&--preview {
            margin-bottom: 0;
        }
    }
}
