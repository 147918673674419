/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| ALERT
|--------------------------------------------------------------------------
*/

.alert {
    width: 18.75rem;
    min-height: 5.5rem;
    border: border.$bw-thin solid color.$color-gray-100;
    border-radius: border.$radii-md;
    background-color: color.$color-background-50;
    box-shadow: shadow.$shadow-alert;
    animation-name: animate-alert;
    animation-duration: 1s;

    display: none;

    &--fixed {
        z-index: 7;
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &--close {
        display: none;
    }

    &--show {
        display: block;
    }

    &__wrapper {
        width: 100%;
        min-height: inherit;
        padding: space.$space-8;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__content {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        span, button {
            margin: 0 space.$space-8 0;
        }

        p {
            font-size: font.$fs-sm;
            font-weight: font.$fw-medium;
            line-height: font.$lh-short;
            margin: 0 space.$space-8;
        }
    }

    &__footer {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
