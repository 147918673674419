/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/animate';
@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/opacities' as opacity;

/*
|--------------------------------------------------------------------------
| COUNCILOR TRIGGER OPTIONS
|--------------------------------------------------------------------------
*/

.councilor-trigger-options {
    z-index: 100;
    position: fixed;
    inset: auto 0 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    // min-height: 22.5rem;
    padding: space.$space-48;
    background: color.$color-gradient-100;
	// background: color.$color-500;

    // animation-name: animate-vote-options;
    // animation-duration: 1s;

	&--toggle {
		position: absolute;
		top: -25px;
		right: 50px;
	}

	&--show {
		min-height: 22.5rem;
	}

	&--close {
		height: 0;
		padding: space.$space-24;

		.councilor-trigger-options__content,
		.councilor-trigger-options__trigger {
			display: none;
		}
	}

    &__content {
        max-width: 43.75rem;
        text-align: center;

        p {
            font-weight: font.$fw-semi-bold;
            color: color.$color-white;
        }
    }

    &__title {
        margin-bottom: space.$space-24;
        color: color.$color-white;
    }
}
