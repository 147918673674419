/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/shadows' as shadow;

/*
|--------------------------------------------------------------------------
| BADGES
|--------------------------------------------------------------------------
*/

.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: 2.5rem;
    width: fit-content;
    min-height: 2.5rem;
    height: auto;
    margin: space.$space-4;
    border-radius: border.$radii-md;
    background-color: color.$color-gray-50;

    &__caption {
        display: inline-block;

        min-height: inherit;
        padding: space.$space-4 space.$space-8;
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        background-color: color.$color-gray-200;

        font-size: font.$fs-xs;
        font-weight: font.$fw-medium;
    }

    &__info {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        min-height: inherit;
        padding: 0 space.$space-8;

        text-transform: uppercase;
        font-weight: font.$fw-semi-bold;
        font-size: font.$fs-xs;
        line-height: 260%;
    }

    &--full {
        width: 90%;

        display: flex;
        justify-content: flex-start;
    }

    &--rounded {
        width: 2.5rem;
        border-radius: border.$radii-full;
        box-shadow: shadow.$shadow-light-2;
        display: flex;
        align-items: center;
        justify-content: center;

        .badge__info {
            flex: none;
            font-family: font.$ff-highlight;
            font-weight: font.$fw-bold;
        }
    }

    &--small {
        min-width: 1rem;
        min-height: 1rem;
        padding: 0 space.$space-4;
    }

    &--large {
        padding: space.$space-8;

		.badge__info,
		.badge__caption {
			font-size: font.$fs-md;
		}
    }

    &--success {
        color: color.$color-black-50;
        background-color: color.$color-green-50;
    }

    &--light {
        background-color: color.$color-gray-200;
    }

    &--warning {
        color: color.$color-black-50;
        background-color: color.$color-yellow-50;
    }

    &--danger {
        color: color.$color-white;
        background-color: color.$color-red-50;
    }

    &--primary {
        color: color.$color-white;
        background-color: color.$color-700;
        filter: brightness(130%);
    }

    &--secondary {
        color: color.$color-700;
        background-color: color.$color-500;
        filter: brightness(110%);
    }

    &--tertiary {
        color: color.$color-700;
        background-color: color.$color-400;
        filter: brightness(120%);
    }

    &--quaternary {
        color: color.$color-text-50;
        background-color: color.$color-300;
    }

    &--quinary {
        color: color.$color-text-50;
        background-color: color.$color-200;
    }

    &--senary {
        color: color.$color-text-50;
        background-color: color.$color-100;
    }

    &--warning,
    &--danger,
    &--primary,
    &--secondary,
    &--tertiary,
    &--quaternary,
    &--quinary,
    &--senary {
        .badge__caption {
            font-weight: font.$fw-medium;
            color: color.$color-text-50;
        }
    }
}

/* --------------------------------------------------------------------------
| MEDIA PRINT...
|-------------------------------------------------------------------------- */

@media only print {
	.badge {
		display: block;

		background-color: red;

		&__caption {
			display: inline-block;

			min-height: inherit;
			padding: space.$space-4 space.$space-8;
			border-top-left-radius: inherit;
			border-bottom-left-radius: inherit;
			background-color: color.$color-gray-200;

			font-size: font.$fs-xs;
			font-weight: font.$fw-medium;
		}

		&__info {
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;

			min-height: inherit;
			padding: 0 space.$space-8;

			text-transform: uppercase;
			font-weight: font.$fw-semi-bold;
			font-size: font.$fs-xs;
			line-height: 260%;
		}
	}
}
