/*
|--------------------------------------------------------------------------
| IMPORTS...
|--------------------------------------------------------------------------
*/

@use '../../abstracts/fonts' as font;
@use '../../abstracts/colors' as color;
@use '../../abstracts/spacings' as space;
@use '../../abstracts/borders' as border;
@use '../../abstracts/opacities' as opacity;
@use '../../abstracts/breackpoints' as break;

/*
|--------------------------------------------------------------------------
| CARD SPEAK CONTROL...
|--------------------------------------------------------------------------
*/

.speak {
    position: relative;

    width: 100%;
    padding: space.$space-32;
    border-radius: border.$radii-lg;
    border: border.$bw-thin solid color.$color-gray-50;
    background-color: color.$color-white;

    &__wrapper {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
    }

    &__inner {
        order: 2;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        &__header {
            width: 12rem;
            border-radius: border.$radii-md;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &__avatar {
                position: relative;

                width: 10rem;
                height: 10rem;
				border: border.$bw-2thick solid color.$color-gray-200;
                border-radius: border.$radii-full;
                background-color: color.$color-white;
                // outline-offset: 0.25rem;
                // outline: border.$bw-thin solid color.$color-500;

                display: flex;
                align-items: center;
                justify-content: center;

                img,
                svg {
                    width: 100%;
                    height: 100%;
                    border-radius: border.$radii-full;
                    object-fit: cover;
                    object-position: top;
                }

                &__letter {
                    position: absolute;
                    top: 5%;
                    right: 3%;

                    width: 1.5rem;
                    height: 1.5rem;
                    border-radius: border.$radii-full;
                    background-color: color.$color-300;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h6 {
                        font-size: font.$fs-2xs;
                        text-transform: uppercase;
                        margin: 0;
                    }
                }
            }
        }

        &__body {
            // flex: 1;
            padding: 0 space.$space-24;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h6 {
                font-size: font.$fs-md;
            }

            p {
                font-size: font.$fs-sm;
            }
        }
    }

    &__footer {
        order: 1;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
